/* ==========DashBoard================= */
.marginside {
    padding-left: 2%;
    padding-right: 2%;
}

.marginhcard {
    padding-left: 0 !important;
}

/* ===============Dashboard==================== */

/* CSS */
.debit-amount {
    color: red;
  }
  
  .credit-amount {
    color: green;
  }

  /* ------------------------------------ */

  
  /* Add this CSS to style the TransactionDetails component for a card-based layout */

.transaction-history {
  padding: 20px;
}

.transaction-card {
  border: 1px solid #ccc;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  padding: 20px;
  margin: 10px;
}

.transaction-card h3 {
  font-size: 16px;
  margin-bottom: 10px;
}

.transaction-card p {
  margin: 5px 0;
}
